import ImageFactory from '../../../adapters/cloudinary/ImageFactory';
import { optimizeImage } from '../../../adapters/helpers/pagespeedHelper';

export const roundUpTheSize = (width) => {
    return Math.floor(width / 100) * 100 + 100;
}

export const getBackgroundImage = (contentImageId, width, transformations, format) => {
    if (contentImageId) {
        return `url(${optimizeImage(ImageFactory.buildContentfullImageUrlByWidth(contentImageId, width, transformations, format))})`
    }
}
